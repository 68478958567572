<template>
  <div
    class="text-center mt-5 home-content"
  >
    <b-img
      :src="require('@/assets/logo/logo.png')"
      alt="logo"
      height="200"
    />
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
}
</script>

<style scoped>
.home-content {
  opacity: 0.3;
}
</style>
